import React from "react";
import theme from "theme";
import { Theme, Box, Text, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Піцерія Дивовижна Італія
			</title>
			<meta name={"description"} content={"Скуштуйте найкращі страви італійської кухні"} />
			<meta property={"og:title"} content={"Піцерія Дивовижна Італія"} />
			<meta property={"og:description"} content={"Скуштуйте найкращі страви італійської кухні"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6698f9e3995d120018f20fe0/images/nero00007-83425ddc.png?v=2024-07-18T11:35:43.635Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6698f9e3995d120018f20fe0/images/nero00007-83425ddc.png?v=2024-07-18T11:35:43.635Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6698f9e3995d120018f20fe0/images/nero00007-83425ddc.png?v=2024-07-18T11:35:43.635Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6698f9e3995d120018f20fe0/images/nero00007-83425ddc.png?v=2024-07-18T11:35:43.635Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6698f9e3995d120018f20fe0/images/nero00007-83425ddc.png?v=2024-07-18T11:35:43.635Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6698f9e3995d120018f20fe0/images/nero00007-83425ddc.png?v=2024-07-18T11:35:43.635Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6698f9e3995d120018f20fe0/images/nero00007-83425ddc.png?v=2024-07-18T11:35:43.635Z"} />
		</Helmet>
		<Components.Header12>
			<Override slot="link1" />
			<Override slot="link" />
			<Override slot="link2" />
			<Override slot="link3" />
		</Components.Header12>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-6">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			{"    "}{"    "}
			<Box
				lg-width="100%"
				lg-align-items="center"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="100%"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			/>
			{"    "}
			<Box
				display="flex"
				width="45%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
			>
				{"        "}
				<Text
					font="--headline3"
					lg-text-align="center"
					margin="16px 0px 24px 0px"
					color="--grey"
					md-text-align="left"
				>
					{"            "}Піцерія Дивовижна Італія була заснована з метою привнести нашому співтовариству аутентичний смак Італії.{"\n        "}
				</Text>
				{"        "}
				<Box
					width="100%"
					height="auto"
					overflow-y="hidden"
					hover-transform="translateY(-10px)"
					padding="0px 0px 125% 0px"
					overflow-x="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
				>
					{"            "}
					<Image
						top="-64px"
						right="-21px"
						src="https://uploads.quarkly.io/6698f9e3995d120018f20fe0/images/big-pizza-hero-2022-768x1024.webp?v=2024-07-18T11:35:43.666Z"
						display="block"
						width="100%"
						bottom="auto"
						min-height="100%"
						object-fit="cover"
						position="absolute"
						left="auto"
						height="528.641px"
						srcSet="https://smartuploads.quarkly.io/6698f9e3995d120018f20fe0/images/big-pizza-hero-2022-768x1024.webp?v=2024-07-18T11%3A35%3A43.666Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6698f9e3995d120018f20fe0/images/big-pizza-hero-2022-768x1024.webp?v=2024-07-18T11%3A35%3A43.666Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6698f9e3995d120018f20fe0/images/big-pizza-hero-2022-768x1024.webp?v=2024-07-18T11%3A35%3A43.666Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6698f9e3995d120018f20fe0/images/big-pizza-hero-2022-768x1024.webp?v=2024-07-18T11%3A35%3A43.666Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6698f9e3995d120018f20fe0/images/big-pizza-hero-2022-768x1024.webp?v=2024-07-18T11%3A35%3A43.666Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6698f9e3995d120018f20fe0/images/big-pizza-hero-2022-768x1024.webp?v=2024-07-18T11%3A35%3A43.666Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6698f9e3995d120018f20fe0/images/big-pizza-hero-2022-768x1024.webp?v=2024-07-18T11%3A35%3A43.666Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					{"        "}
				</Box>
				{"    "}
			</Box>
			{"    "}
			<Box
				lg-order="1"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				display="flex"
				width="55%"
				flex-direction="column"
			>
				{"        "}
				<Box
					height="auto"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 45% 0px"
					width="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					{"            "}
					<Image
						display="block"
						top="auto"
						right={0}
						bottom="0px"
						src="https://uploads.quarkly.io/6698f9e3995d120018f20fe0/images/nero00007-83425ddc.png?v=2024-07-18T11:35:43.635Z"
						object-fit="cover"
						left={0}
						min-height="100%"
						position="absolute"
						width="100%"
						srcSet="https://smartuploads.quarkly.io/6698f9e3995d120018f20fe0/images/nero00007-83425ddc.png?v=2024-07-18T11%3A35%3A43.635Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6698f9e3995d120018f20fe0/images/nero00007-83425ddc.png?v=2024-07-18T11%3A35%3A43.635Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6698f9e3995d120018f20fe0/images/nero00007-83425ddc.png?v=2024-07-18T11%3A35%3A43.635Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6698f9e3995d120018f20fe0/images/nero00007-83425ddc.png?v=2024-07-18T11%3A35%3A43.635Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6698f9e3995d120018f20fe0/images/nero00007-83425ddc.png?v=2024-07-18T11%3A35%3A43.635Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6698f9e3995d120018f20fe0/images/nero00007-83425ddc.png?v=2024-07-18T11%3A35%3A43.635Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6698f9e3995d120018f20fe0/images/nero00007-83425ddc.png?v=2024-07-18T11%3A35%3A43.635Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					{"        "}
				</Box>
				{"        "}
				<Text
					font="--base"
					lg-text-align="center"
					margin="24px 0px 48px 0px"
					color="--grey"
					md-text-align="left"
				>
					{"            "}Наша пристрасть до піци відображається в кожному аспекті нашого ресторану, від уважно відібраних інгредієнтів до майстерності в приготуванні.{"\n        "}
				</Text>
				{"        "}
				<Box
					position="relative"
					padding="0px 0px 45% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
				>
					{"            "}
					<Image
						object-fit="cover"
						width="100%"
						bottom="-257px"
						min-height="100%"
						src="https://uploads.quarkly.io/6698f9e3995d120018f20fe0/images/z-grusheyu-2-500x500.webp?v=2024-07-18T11:35:43.654Z"
						position="absolute"
						display="block"
						top="-14px"
						left="auto"
						right="-7px"
						srcSet="https://smartuploads.quarkly.io/6698f9e3995d120018f20fe0/images/z-grusheyu-2-500x500.webp?v=2024-07-18T11%3A35%3A43.654Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6698f9e3995d120018f20fe0/images/z-grusheyu-2-500x500.webp?v=2024-07-18T11%3A35%3A43.654Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6698f9e3995d120018f20fe0/images/z-grusheyu-2-500x500.webp?v=2024-07-18T11%3A35%3A43.654Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6698f9e3995d120018f20fe0/images/z-grusheyu-2-500x500.webp?v=2024-07-18T11%3A35%3A43.654Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6698f9e3995d120018f20fe0/images/z-grusheyu-2-500x500.webp?v=2024-07-18T11%3A35%3A43.654Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6698f9e3995d120018f20fe0/images/z-grusheyu-2-500x500.webp?v=2024-07-18T11%3A35%3A43.654Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6698f9e3995d120018f20fe0/images/z-grusheyu-2-500x500.webp?v=2024-07-18T11%3A35%3A43.654Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					{"        "}
				</Box>
				{"        "}
				<Text
					font="--base"
					lg-text-align="center"
					margin="24px 0px 48px 0px"
					color="--grey"
					md-text-align="left"
				>
					{"            "}Наші досвідчені кухарі, навчені традиційним італійським технікам виготовлення піци, гарантують, що кожна піца, яка виходить з нашої печі, є свідченням нашої пристрасті до якості і смаку.{"\n        "}
				</Text>
				{"    "}
			</Box>
		</Section>
		<Section
			padding="60px 0 60px 0"
			md-padding="80px 0 80px 0"
			background="--color-darkL1"
			color="--dark"
			quarkly-title="Reviews-14"
		>
			<Override slot="SectionContent" md-padding="0px 0 0px 0" grid-template-columns="repeat(3, 1fr)" grid-template-rows="auto" />
			{"    "}{"    "}
			<Text margin="0px 0px 3rem 0px" color="--light" font="normal 600 46px/1.2 --fontFamily-sans" text-align="center">
				{"        "}Чому обирати Піцерія Дивовижна Італія?{"\n    "}
			</Text>
			{"    "}
			<Box
				min-width="100px"
				min-height="100px"
				border-radius="8px"
				margin="0px 20px 25px 20px"
				display="grid"
				flex-direction="row"
				justify-content="space-between"
				lg-margin="0px 0 25px 0px"
				md-flex-direction="column"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
			>
				{"        "}
				<Box
					min-width="100px"
					min-height="100px"
					border-color="#202757"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					md-border-width="0 0 1px 0"
					md-margin="0px 0px 35px 0px"
				>
					{"            "}
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 10px 0px">
						{"                "}
						<Text margin="0px 0px 0 0px" font="--lead" color="#b2bcc3">
							{"                    "}Наше меню пропонує традиційні італійські рецепти, передані з покоління в покоління, забезпечуючи аутентичний кулінарний досвід.{"\n                "}
						</Text>
						{"            "}
					</Box>
					{"        "}
				</Box>
				{"        "}
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					md-margin="0px 0px 35px 0px"
				>
					{"            "}
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 15px 0px\n\n">
						{"                "}
						<Text margin="0px 0px 0 0px" font="--lead" color="#b2bcc3">
							{"                    "}Відібрані інгредієнти найвищої якості забезпечують смачність і якість кожної страви.{"\n                "}
						</Text>
						{"            "}
					</Box>
					{"        "}
				</Box>
				{"        "}
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					md-margin="0px 0px 35px 0px"
				>
					{"            "}
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 15px 0px">
						{"                "}
						<Text margin="0px 0px 0 0px" font="--lead" color="#b2bcc3">
							{"                    "}Ми пропонуємо широкий вибір піци, що відповідає різним смакам і вподобанням.{"\n                "}
						</Text>
						{"            "}
					</Box>
					{"        "}
				</Box>
				{"    "}
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-6">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				lg-width="100%"
				lg-align-items="center"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="100%"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					width="80%"
					sm-margin="0px 0px 30px 0px"
					sm-width="100%"
					sm-text-align="left"
					sm-font="--headline3"
				>
					Скуштуйте традицію
				</Text>
			</Box>
			<Box
				display="flex"
				width="45%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
			>
				<Box
					width="100%"
					height="auto"
					overflow-y="hidden"
					hover-transform="translateY(-10px)"
					padding="0px 0px 125% 0px"
					overflow-x="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						top={0}
						right={0}
						src="https://uploads.quarkly.io/6698f9e3995d120018f20fe0/images/sirna_uuu-min-634x420.png?v=2024-07-18T11:35:43.680Z"
						display="block"
						width="100%"
						bottom={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
						left={0}
						srcSet="https://smartuploads.quarkly.io/6698f9e3995d120018f20fe0/images/sirna_uuu-min-634x420.png?v=2024-07-18T11%3A35%3A43.680Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6698f9e3995d120018f20fe0/images/sirna_uuu-min-634x420.png?v=2024-07-18T11%3A35%3A43.680Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6698f9e3995d120018f20fe0/images/sirna_uuu-min-634x420.png?v=2024-07-18T11%3A35%3A43.680Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6698f9e3995d120018f20fe0/images/sirna_uuu-min-634x420.png?v=2024-07-18T11%3A35%3A43.680Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6698f9e3995d120018f20fe0/images/sirna_uuu-min-634x420.png?v=2024-07-18T11%3A35%3A43.680Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6698f9e3995d120018f20fe0/images/sirna_uuu-min-634x420.png?v=2024-07-18T11%3A35%3A43.680Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6698f9e3995d120018f20fe0/images/sirna_uuu-min-634x420.png?v=2024-07-18T11%3A35%3A43.680Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Text
					font="--base"
					lg-text-align="center"
					margin="24px 0px 0px 0px"
					color="--darkL2"
					md-text-align="left"
				>
					У Піцерія Дивовижна Італія ми віримо, що чудова піца починається з чудових інгредієнтів. Саме тому ми використовуємо найсвіжіші продукти, найкраще м'ясо та найароматніші сири як від місцевих постачальників, так і від перевірених італійських імпортерів.{" "}
				</Text>
			</Box>
			<Box
				lg-order="1"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				display="flex"
				width="55%"
				flex-direction="column"
			>
				<Box
					height="auto"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 45% 0px"
					width="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						display="block"
						top="auto"
						right={0}
						bottom="0px"
						src="https://uploads.quarkly.io/6698f9e3995d120018f20fe0/images/nero00003-ed781c87.png?v=2024-07-18T11:35:43.652Z"
						object-fit="cover"
						left={0}
						min-height="100%"
						position="absolute"
						width="100%"
						srcSet="https://smartuploads.quarkly.io/6698f9e3995d120018f20fe0/images/nero00003-ed781c87.png?v=2024-07-18T11%3A35%3A43.652Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6698f9e3995d120018f20fe0/images/nero00003-ed781c87.png?v=2024-07-18T11%3A35%3A43.652Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6698f9e3995d120018f20fe0/images/nero00003-ed781c87.png?v=2024-07-18T11%3A35%3A43.652Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6698f9e3995d120018f20fe0/images/nero00003-ed781c87.png?v=2024-07-18T11%3A35%3A43.652Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6698f9e3995d120018f20fe0/images/nero00003-ed781c87.png?v=2024-07-18T11%3A35%3A43.652Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6698f9e3995d120018f20fe0/images/nero00003-ed781c87.png?v=2024-07-18T11%3A35%3A43.652Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6698f9e3995d120018f20fe0/images/nero00003-ed781c87.png?v=2024-07-18T11%3A35%3A43.652Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Text
					font="--base"
					lg-text-align="center"
					margin="24px 0px 48px 0px"
					color="--darkL2"
					md-text-align="left"
				>
					Тісто готується щодня, повільно піднімається, щоб отримати легку, повітряну текстуру, і випікається до досконалості в нашій дров'яній печі.
				</Text>
				<Box
					position="relative"
					padding="0px 0px 45% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
				>
					<Image
						object-fit="cover"
						width="100%"
						bottom="-271px"
						min-height="100%"
						src="https://uploads.quarkly.io/6698f9e3995d120018f20fe0/images/pizzza.webp?v=2024-07-18T11:35:43.635Z"
						position="absolute"
						display="block"
						top="0px"
						left="auto"
						right="-5px"
						srcSet="https://smartuploads.quarkly.io/6698f9e3995d120018f20fe0/images/pizzza.webp?v=2024-07-18T11%3A35%3A43.635Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6698f9e3995d120018f20fe0/images/pizzza.webp?v=2024-07-18T11%3A35%3A43.635Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6698f9e3995d120018f20fe0/images/pizzza.webp?v=2024-07-18T11%3A35%3A43.635Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6698f9e3995d120018f20fe0/images/pizzza.webp?v=2024-07-18T11%3A35%3A43.635Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6698f9e3995d120018f20fe0/images/pizzza.webp?v=2024-07-18T11%3A35%3A43.635Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6698f9e3995d120018f20fe0/images/pizzza.webp?v=2024-07-18T11%3A35%3A43.635Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6698f9e3995d120018f20fe0/images/pizzza.webp?v=2024-07-18T11%3A35%3A43.635Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					В результаті виходить піца, яка не тільки має дивовижний смак, але й передає суть італійської кулінарної спадщини.
				</Text>
			</Box>
		</Section>
		<Components.Footer23>
			<Override slot="text" />
			<Override slot="text1" />
			<Override slot="text4" />
			<Override slot="link" />
			<Override slot="text7" />
			<Override slot="text6" />
			<Override slot="text5" />
			<Override slot="text3" />
			<Override slot="text2" />
			<Override slot="linkBox" />
			<Override slot="box" />
		</Components.Footer23>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"658eb1148e2e8e00217540ff"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});